import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react';

interface ModalContextType {
  setIsOpened: (isOpend: boolean) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export interface ModalItemProps {
  isOpened: boolean;
  handleCloseModal: () => void;
}

interface ModalItem {
  checkModalCondition: () => Promise<boolean>;
  modalComponent: React.ComponentType<ModalItemProps>;
  onClose?: () => void;
}

interface ModalProviderProps {
  modals: ModalItem[];
  children: ReactNode;
}

export const ModalProvider = ({ modals, children }: ModalProviderProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);

  const checkAndDisplayModal = useCallback(async () => {
    for (let i = currentModalIndex; i < modals.length; i++) {
      const modal = modals[i];
      const shouldShowModal = await modal.checkModalCondition();
      if (shouldShowModal) {
        setCurrentModalIndex(i);
        setIsOpened(true);
        return;
      }
    }
  }, [modals, currentModalIndex]);

  useEffect(() => {
    if (!isOpened && currentModalIndex < modals.length) {
      checkAndDisplayModal();
    }
  }, [isOpened, currentModalIndex, modals, checkAndDisplayModal]);

  const handleCloseModal = () => {
    const currentModal = modals[currentModalIndex];

    if (currentModal?.onClose) {
      currentModal.onClose();
    }

    setIsOpened(false);
    setCurrentModalIndex((prevIndex) => (modals.length > 1 ? prevIndex + 1 : prevIndex));
  };

  const value: ModalContextType = { setIsOpened: setIsOpened };
  const ModalComponent = modals[currentModalIndex]?.modalComponent;

  return (
    <ModalContext.Provider value={value}>
      {children}
      {ModalComponent && React.createElement(ModalComponent, { isOpened, handleCloseModal })}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(`useModalContext must be used within a ModalProvider`);
  }
  return context;
};
