import { UpdateMobileVersionModal } from 'features/eiq-connect/modals/update-mobile-version-modal/update-mobile-version-modal';
import { shouldShowUpdateModal } from 'features/eiq-connect/modals/update-mobile-version-modal/update-mobile-version-modal.functions';
import { useEffect, useState } from 'react';
import { ModalProvider } from '../modal/modal-context/modal-context';
import { AppWrapperProps } from './wrappers.types';

export const GlobalModalWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  const [shouldShowUpdateModalState, setShouldShowUpdateModalState] = useState(false);

  const globalModalsInModalProvider = [
    {
      checkModalCondition: async () => shouldShowUpdateModalState,
      modalComponent: UpdateMobileVersionModal,
      onClose: () => setShouldShowUpdateModalState(false),
    },
  ];

  const checkUpdateCondition = async () => {
    const result = await shouldShowUpdateModal();
    setShouldShowUpdateModalState(result);
  };

  useEffect(() => {
    checkUpdateCondition();

    // check every minute
    const intervalId = setInterval(checkUpdateCondition, 1 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <ModalProvider modals={globalModalsInModalProvider}>{children}</ModalProvider>;
};
