import {
  NOTIFICATION_CONFITMATION_MODAL_SHOWN_ONCE_KEY,
  NOTIFICATION_UNSUBSCRIBE_DATE_KEY,
} from 'common/stores/notification-confirmation-modal/notification-confirmaiton-store.constants';
import { ServerClient } from 'common/utils/http/http-clients';
import { MassNotificationSettings } from '../residents-service/residents.service.types';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { IMarkNotificationsRequest, INotification, INotificationResponse } from './notification.service.types';

import { INotificationPreviewData } from 'features/eiq-connect/pages/notifications/notifications.types';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { IPagination } from 'common/models/pagination.interface';
import dayjs from 'dayjs';

class NotificationService {
  getPreviewNotificationsData = (limit: number) => {
    Promise.all([this.getAll({ limit, offset: 0 }, null, ' isRead'), this.getUnreadCount()]).then(([data, unreadCount]) =>
      eiqConnectStore.setNotificationPreviewData({ items: data.items, unreadCount }),
    );
  };

  async getAll(pageLimit: IPagination, filter: string | null | undefined, sort: string | null | undefined): Promise<INotificationResponse> {
    const residentId = userAuthStore.webGuestResidentId;
    const res = await ServerClient.get<INotificationResponse>(`/residents/${residentId}/notifications`, {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async getUnreadCount(): Promise<number> {
    const residentId = userAuthStore.webGuestResidentId;
    const res = await ServerClient.get<number>(`/residents/${residentId}/notifications/unread-count`);
    return res?.data;
  }

  async getPreviewData(limit: number): Promise<INotificationPreviewData> {
    const res = await ServerClient.get<INotificationPreviewData>(`https://localhost:7021/api/notifications/data`, {
      params: {
        limit,
      },
    });
    return res?.data;
  }

  async markNotifications(data: IMarkNotificationsRequest): Promise<void> {
    const residentId = userAuthStore.webGuestResidentId;
    const res = await ServerClient.put<void>(`/residents/${residentId}/notifications`, data);
    return res?.data;
  }

  async readAllNotifications(): Promise<void> {
    const residentId = userAuthStore.webGuestResidentId;
    const res = await ServerClient.put<void>(`/residents/${residentId}/notifications/read-all`);
    return res?.data;
  }

  async deleteNotifications(notificationIds: number[]): Promise<void> {
    const residentId = userAuthStore.webGuestResidentId;
    const res = await ServerClient.request<void>({
      method: 'DELETE',
      url: `/residents/${residentId}/notifications`,
      data: { residentNotificationIds: notificationIds },
    });
    return res?.data;
  }

  checkIfNotificationModalShouldBeShown = async (): Promise<boolean> => {
    try {
      const modalShownThisSession = sessionStorage.getItem(NOTIFICATION_CONFITMATION_MODAL_SHOWN_ONCE_KEY);
      if (modalShownThisSession) {
        return false;
      }

      const shouldShowFirstTime: boolean = this.getIsConfirmationModalToShowFirstTime();
      const shouldShowAfterUnsubscribe = this.getIsConfirmationModalToShowAfterUnsubscribe();

      if (shouldShowFirstTime || shouldShowAfterUnsubscribe) {
        sessionStorage.setItem(NOTIFICATION_CONFITMATION_MODAL_SHOWN_ONCE_KEY, `${true}`);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error checking modal status');
      return false;
    }
  };

  updateMassNotificationSettings = async (residentId: number, data: MassNotificationSettings): Promise<void> => {
    const res = await ServerClient.put<void>(`/residents/${residentId}/mass-settings`, data);
    return res?.data;
  };

  private getIsConfirmationModalToShowFirstTime = (): boolean => {
    if (!eiqConnectStore.residentDetails) {
      return false;
    }

    const allowedMassNotifications = eiqConnectStore.massNotificationSettings;

    if (
      allowedMassNotifications &&
      allowedMassNotifications.isMassSMSAllowed === null &&
      allowedMassNotifications.isMassEmailAllowed === null &&
      allowedMassNotifications.isMassPushAllowed === null
    ) {
      return true;
    }

    return false;
  };

  private getIsConfirmationModalToShowAfterUnsubscribe = (): boolean => {
    const unsubscribeDateValue = localStorage.getItem(NOTIFICATION_UNSUBSCRIBE_DATE_KEY);
    const wasUnsubscribed = this.wasUnsubscribed();

    if (wasUnsubscribed === null) {
      return false;
    }

    if (!wasUnsubscribed) {
      if (unsubscribeDateValue) {
        localStorage.removeItem(NOTIFICATION_UNSUBSCRIBE_DATE_KEY);
      }
      return false;
    }

    if (!unsubscribeDateValue) {
      this.saveUnsubscribeDate();
      return false;
    }

    if (unsubscribeDateValue) {
      const currentDate = dayjs();
      const unsubscribeDate = dayjs(unsubscribeDateValue);
      const oneMonthLater = unsubscribeDate.add(1, 'month');

      return currentDate.isAfter(oneMonthLater);
    }

    return false;
  };

  private wasUnsubscribed = (): boolean | null => {
    if (!eiqConnectStore.residentDetails) {
      return null;
    }

    const { isMassSMSAllowed, isMassEmailAllowed } = eiqConnectStore.massNotificationSettings || {};

    return !isMassSMSAllowed && !isMassEmailAllowed;
  };

  private saveUnsubscribeDate = () => {
    const unsubscribeDate = dayjs().format('YYYY-MM-DD');
    localStorage.setItem(NOTIFICATION_UNSUBSCRIBE_DATE_KEY, unsubscribeDate);
  };
}

export const notificaitonService = new NotificationService();
