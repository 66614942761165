import { Button, Col, Form, Input, Row } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { ReactComponent as LoginIcon } from 'assets/icons/eiq-connect/login.svg';
import { resetPassword, signUpValidation } from 'features/eiq-connect-login/validation/login.validation';
import './verification-code.scss';
import { ISignUpResident } from 'features/eiq-connect-login/service/sign-up.types';
import wgSignUpService from 'features/eiq-connect-login/service/sign-up.service';
import { isEmail } from 'common/helpers/email.helper';
import { isPhoneNumber } from 'common/helpers/phone.helper';
import { ReactComponent as EyeIcon } from 'assets/icons/eiq-connect/eye.svg';
import { ReactComponent as EyeCrossIcon } from 'assets/icons/eiq-connect/eye-cross.svg';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { VERIFICATION_CODE_EXPIRATION_TIME_IN_SECONDS } from 'common/constans/common.constants';

enum VerificationType {
  Email = 0,
  Phone,
}

export const VerificationPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [verificationType, setVerificationType] = useState<any>(null);
  const [timeLeft, setTimeLeft] = useState(VERIFICATION_CODE_EXPIRATION_TIME_IN_SECONDS);
  const [showResend, setShowResend] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useLayoutEffect(() => {
    const isSuccessfullySaved = sessionStorage.getItem('isSuccessfullySaved');
    if (isSuccessfullySaved) {
      setIsSuccess(true);
    } else {
      const savedData = sessionStorage.getItem('userData');
      if (savedData) {
        userAuthStore.setSignUpValues(JSON.parse(savedData));
      } else {
        navigate('/sign-up');
      }
    }
  }, []);

  useLayoutEffect(() => {
    setVerificationType(determineLoginType(userAuthStore.signUpValues?.login));
  }, [userAuthStore.signUpValues]);

  useEffect(() => {
    if (timeLeft <= 0) {
      setShowResend(true);
      return;
    } else {
      setShowResend(false);
    }
    const timerId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [timeLeft]);

  const determineLoginType = (value: string) => {
    const isEmailCorrectFormat = isEmail(value);
    const isPhoneCorrectFormat = isPhoneNumber(value);

    if (isEmailCorrectFormat) return VerificationType.Email;
    if (isPhoneCorrectFormat) return VerificationType.Phone;
  };

  const signUpRequestBodyBuilder = (verificationCode: any, password: string): ISignUpResident => {
    return {
      login: userAuthStore.signUpValues.login,
      password: password,
      pin: userAuthStore.signUpValues.pin,
      verificationCode: verificationCode,
    };
  };

  const signUp = (body: ISignUpResident) => {
    wgSignUpService
      .signUp(body)
      .then((res) => {
        if (res === '') {
          setIsSuccess(true);
          sessionStorage.setItem('isSuccessfullySaved', JSON.stringify(true));
          sessionStorage.removeItem('userData');
        } else {
          setIsSuccess(false);
        }
      })
      .catch((error) => {
        setIsSuccess(false);
      });
  };

  const onResendCode = () => {
    wgSignUpService
      .verify(userAuthStore.signUpValues.login, userAuthStore.signUpValues.pin)
      .then((_res) => {
        setTimeLeft(VERIFICATION_CODE_EXPIRATION_TIME_IN_SECONDS);
      })
      .catch((error) => {});
  };

  const onFinish = (values: any) => {
    const signUpRequestBody: ISignUpResident = signUpRequestBodyBuilder(values.verificationCode, values.password);
    signUp(signUpRequestBody);
  };

  if (userAuthStore.isLogin) {
    return <Navigate to="/eiq-connect" />;
  }

  const getLifeTime = (time: number) => {
    const minutes = (time / 60) | 0;
    return `${minutes}:${getLifeTimeSeconds(time - minutes * 60)}`;
  };

  const getLifeTimeSeconds = (time: number) => {
    if (time > 9) {
      return time;
    }
    return `0${time}`;
  };

  return (
    <FormWrapper
      className="verification-code-form-wrapper eiq-connect-auth-form-wrapper"
      form={form}
      onFinish={onFinish}
      name="validateOnly"
      layout="vertical"
      autoComplete="on"
      initialValues={{ remember: true }}>
      <Row>
        {isSuccess ? (
          <Col>
            <div className="title">Successfully signed up</div>
            <div className="text">Now you can fill in credentials and log in to your account!</div>
          </Col>
        ) : (
          <Col>
            <div className="title">Verification code</div>
            <div className="text">
              Check your {verificationType === VerificationType.Email ? 'email' : 'SMS'}. We sent a verification code to{' '}
              {userAuthStore.signUpValues?.login}
            </div>
            <div className="text">Please enter it below:</div>
          </Col>
        )}
      </Row>
      {isSuccess ? (
        <div className="action-line">
          <Button type="primary" icon={<LoginIcon />} style={{ width: '100%' }} onClick={() => navigate('/login')}>
            Log in
          </Button>
        </div>
      ) : (
        <>
          <Form.Item name="verificationCode" label="Verification code" rules={signUpValidation.verificationCode}>
            <Input name="verificationCode" placeholder="Enter verification code" />
          </Form.Item>

          <Form.Item name="password" label="Choose password (Min 8 characters)" rules={resetPassword.password}>
            <Input.Password
              size={'large'}
              name="password"
              placeholder="Enter password"
              iconRender={(visible) => {
                if (visible) {
                  return <EyeCrossIcon />;
                }
                return <EyeIcon />;
              }}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm password (Same password)"
            rules={resetPassword.password2}
            dependencies={['password']}>
            <Input.Password
              size={'large'}
              placeholder="Re-enter password"
              iconRender={(visible) => {
                if (visible) {
                  return <EyeCrossIcon />;
                }
                return <EyeIcon />;
              }}
            />
          </Form.Item>

          <div className="action-line">
            <Button htmlType="submit" type="primary" icon={<LoginIcon />} style={{ width: '100%' }}>
              Sign up
            </Button>
          </div>

          <div className="text text-center ant-mt-16">
            {showResend ? (
              <>
                <Button className="resend-btn" type="link" onClick={onResendCode}>
                  Resend code
                </Button>
              </>
            ) : (
              <>
                Resend code after <span className="timer">{getLifeTime(timeLeft)}</span>
              </>
            )}
          </div>
          <div className="text text-center ant-mt-16 return-back">
            <Button
              className="resend-btn"
              type="link"
              onClick={() => {
                navigate(-1);
              }}
              icon={<ArrowLeftOutlined />}>
              Back
            </Button>
          </div>
        </>
      )}
    </FormWrapper>
  );
};

export const Verification = observer(VerificationPage);
