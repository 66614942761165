import { useModalContext } from 'common/components/modal/modal-context/modal-context';

export const MassNotificationBlock = () => {
  const context = useModalContext();
  return (
    <div>
      <p>
        Please check your communication settings. If you have not already provided consent for us to communicate with you via email or SMS,
        you won’t be receiving notifications.
      </p>
      <span className="eiq-link" onClick={() => context.setIsOpened(true)}>
        Tap here to see the consent form and details
      </span>
    </div>
  );
};
