import { makeAutoObservable } from 'mobx';
import React from 'react';
import { getItem, removeItem, setItem } from 'common/helpers/authorization-store.helper';
import { MOBILE_APP_CONFIGURATION } from './mobile-app-version.constants';
import mobileAppConfigurationService from 'common/services/mobile-app-configuration-service/mobile-app-configuration-service';
import { IMobileAppVersion, Platform } from 'common/services/mobile-app-configuration-service/mobile-app-configuration-service.types';
import { Capacitor } from '@capacitor/core';
import communityResolverService from 'common/services/community-resolver-service/community-resolver.service';

class MobileAppVersionStore {
  mobileAppVersion: IMobileAppVersion | null = null;

  constructor() {
    makeAutoObservable(this);
    this.restore();
  }

  set(mobileAppConfiguration: IMobileAppVersion) {
    setItem(MOBILE_APP_CONFIGURATION, JSON.stringify(mobileAppConfiguration));
    this.mobileAppVersion = mobileAppConfiguration;
  }

  restore() {
    const source = getItem(MOBILE_APP_CONFIGURATION);
    this.mobileAppVersion = source ? JSON.parse(source) : [];
  }

  clear() {
    removeItem(MOBILE_APP_CONFIGURATION);
    this.mobileAppVersion = null;
  }

  async requestConfiguration() {
    const platform = this.getPlatform();

    if (platform === Platform.Undefined) return;
    if (!communityResolverService.getCurrentCommunityUrl()) return;

    const configuration = await mobileAppConfigurationService.requestVersion(platform);
    this.set(configuration);
  }

  getPlatform(): Platform {
    const platform = Capacitor.getPlatform();

    switch (platform) {
      case Platform.IOS:
        return Platform.IOS;
      case Platform.Android:
        return Platform.Android;
      default:
        return Platform.Undefined;
    }
  }
}

const mobileAppVersionStore = new MobileAppVersionStore();
export const MobileAppVersionStoreContext = React.createContext<MobileAppVersionStore>(mobileAppVersionStore);
export default mobileAppVersionStore;
