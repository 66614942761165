import { SaveOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './notification-confirmation-modal.scss';
import { INotificationChannel, MassNotificationSettings } from 'common/services/residents-service/residents.service.types';
import { notificaitonService } from 'common/services/notification-service/notification.service';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { NotificationCheckboxOptions } from './notification-confirmation.configuration';
import { NotificationType } from 'common/enums/notification-type.enum';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { ModalItemProps } from 'common/components/modal/modal-context/modal-context';

export const NotificationConfirmationModal = ({ isOpened, handleCloseModal }: ModalItemProps) => {
  const residentId: number | null = userAuthStore.webGuestResidentId!;

  const [checkedValues, setCheckedValues] = useState<string[]>([NotificationType.Email, NotificationType.SMS]);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (isOpened) {
      const allowedMassNotifications = eiqConnectStore.massNotificationSettings;
      const updatedCheckedValues: string[] = [];
      if (allowedMassNotifications?.isMassEmailAllowed) {
        updatedCheckedValues.push(NotificationType.Email);
      }
      if (allowedMassNotifications?.isMassSMSAllowed) {
        updatedCheckedValues.push(NotificationType.SMS);
      }
      handleCheckBoxChange(updatedCheckedValues);
    }
  }, [isOpened, residentId]);

  const handleSaveModal = () => {
    const request: MassNotificationSettings = {
      isMassEmailAllowed: checkedValues.includes(NotificationType.Email),
      isMassSMSAllowed: checkedValues.includes(NotificationType.SMS),
    };
    notificaitonService.updateMassNotificationSettings(residentId!, request);

    if (eiqConnectStore.residentDetails?.notificationChannels) {
      const updatedNotificationChannels: INotificationChannel[] = eiqConnectStore.residentDetails.notificationChannels.map((channel) => {
        if (channel.notificationType === NotificationType.Email) {
          return { ...channel, allowsMassNotifications: request.isMassEmailAllowed };
        }
        if (channel.notificationType === NotificationType.SMS) {
          return { ...channel, allowsMassNotifications: request.isMassSMSAllowed };
        }
        return channel;
      });

      eiqConnectStore.residentDetails.notificationChannels = updatedNotificationChannels;
    }

    handleCloseModal();
  };

  const handleCancelModal = () => {
    handleCloseModal();
  };

  const handleCheckBoxChange = (checkedValues: CheckboxValueType[]) => {
    setCheckedValues(checkedValues as string[]);
    setSaveDisabled(checkedValues.length === 0);
  };

  const CancelButton = (
    <Button onClick={handleCancelModal} className="cancel-btn">
      Cancel
    </Button>
  );
  const SaveButton = (
    <Button onClick={handleSaveModal} disabled={saveDisabled} type="primary" className="primary-btn" icon={<SaveOutlined />}>
      Save
    </Button>
  );

  return (
    <div>
      <Modal open={isOpened} footer={null} centered closable={false} wrapClassName="notification-modal">
        <div className="ant-modal-body-form-container">
          <div>
            <h1>Update Communication Preferences</h1>
          </div>
          <div className="ant-modal-body-form-container-text">
            <p>
              We need your consent to be able to send you SMS and Email communications using your phone number and email address in the
              system. At least one communication choice is required for you to be able to receive notifications about your visitors coming
              into the community and important events within your community. Tap here for{' '}
              <Link to={'/eiq-connect/terms-and-condtitions'}>Terms and conditions</Link>.
            </p>
            <Checkbox.Group
              options={NotificationCheckboxOptions}
              value={checkedValues}
              onChange={handleCheckBoxChange}
              className="vertical-checkbox-group"
            />
          </div>
        </div>
        <div className="modal-footer">
          {CancelButton}
          {SaveButton}
        </div>
      </Modal>
    </div>
  );
};
