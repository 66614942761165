import { ServerClient } from 'common/utils/http/http-clients';
import { IMobileAppVersion, Platform } from './mobile-app-configuration-service.types';

class MobileAppConfigurationService {
  async requestVersion(platform: Platform): Promise<IMobileAppVersion> {
    const res = await ServerClient.get<IMobileAppVersion>(`/configurations/version?platform=${platform}`);
    return res.data;
  }
}

export const mobileAppConfigurationService = new MobileAppConfigurationService();
export default mobileAppConfigurationService;
