export interface IMobileAppVersion {
  latest: string;
  minSupported: string;
}

export enum Platform {
  Undefined = 'undefined',
  IOS = 'ios',
  Android = 'android',
}
